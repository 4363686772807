import { useState } from 'react';
import Modal from 'react-modal';

const UploadTipSocial = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <div className='flex flex-col gap-2'>
        <p>
          Are you a digital creator looking to effortlessly download audience
          comments and feedback from your posts? Simply choose your social media
          platform, enter your post's URL.
        </p>
        <div
          className='cursor-pointer text-blue-500'
          onClick={() => setIsOpen(true)}
        >
          Click here to watch video training on how to use the tool.
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel='Training Video'
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background overlay
            zIndex: 1000, // Ensure it has a higher z-index
          },
          content: {
            width: '80%',
            height: '80%',
            margin: 'auto',
            backgroundColor: '#fff',
            padding: 0,
            borderRadius: '8px',
            overflow: 'hidden',
            zIndex: 1001, // Ensure modal content is above other elements
            position: 'relative', // Ensure the close button stays inside the modal
          },
        }}
      >
        {/* Close button with X icon */}
        <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '0px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '48px',
            lineHeight: '48px',
            cursor: 'pointer',
            zIndex: 1002, // Higher than the modal content
          }}
        >
          &times; {/* X icon */}
        </button>

        <div>
          <iframe
            src='https://insightpulse.ai/wp-content/uploads/2024/10/InsightPulse-Training.mp4'
            title='YouTube Video'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            }}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
      </Modal>
    </>
  );
};

export default UploadTipSocial;
