import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useNav } from '@/context/NavContext';
import { useDarkMode } from '@/context/DarkModeContext';
import { useAuthContext } from '@/component/authcheck/AuthCheck';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import Logo from '@/assets/logo.png';
import { ReactComponent as IconHome } from '@/assets/icon-home.svg';
import { ReactComponent as IconDocument } from '@/assets/icon-document.svg';
import { ReactComponent as IconUpload } from '@/assets/icon-upload.svg';
import { ReactComponent as IconChat } from '@/assets/icon-chat.svg';
import { ReactComponent as IconRequest } from '@/assets/icon-request.svg';
import { ReactComponent as IconFaq } from '@/assets/faq.svg';
import { ReactComponent as IconHelp } from '@/assets/icon-help.svg';
import { ReactComponent as IconHow } from '@/assets/question-square.svg';
import { ReactComponent as IconAccount } from '@/assets/icon-account.svg';
import { ReactComponent as IconLogout } from '@/assets/icon-logout.svg';
import { ReactComponent as IconCustomerList } from '@/assets/icon-customer-list.svg';
import { ReactComponent as IconOrders } from '@/assets/icon-orders.svg';
import './SideNav.scss';

const menuItems = [
  { path: '/', label: 'Home', icon: <IconHome /> },
  { path: '/history', label: 'History', icon: <IconDocument /> },
  { path: '/upload', label: 'Upload by Files', icon: <IconUpload /> },
  { path: '/social', label: 'Upload by URLs', icon: <IconUpload /> },
  { path: '/tickethistory', label: 'Request', icon: <IconRequest /> },
  { path: '/faq', label: 'FAQ', icon: <IconHelp /> },
  { path: '/how', label: 'How to use Insights', icon: <IconHow /> },
  { path: '/help', label: 'Help', icon: <IconFaq /> },
  { path: '/submitticket', label: 'Contact Us', icon: <IconChat /> },
];

const adminMenuItems = [
  { path: '/admin/listaccount', label: 'Customers', icon: <IconCustomerList /> },
  { path: '/admin/listorders', label: 'Orders', icon: <IconOrders /> },
  { path: '/admin/listgrants', label: 'Grants', icon: <IconAccount />, role: 'SuperAdmin' },
  { path: '/admin/price', label: 'Price', icon: <CurrencyDollarIcon width={24} />, role: 'SuperAdmin' },
];

const accountMenuItems = [
  { path: '/account', label: 'Account', icon: <IconAccount /> },
  { path: '/signout', label: 'Sign out', icon: <IconLogout /> },
];

const SideNav = () => {
  const { user } = useAuthContext();
  const { collapse } = useNav();
  const { darkMode } = useDarkMode();
  const location = useLocation();
  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(location.pathname.split('/')[1] || '');
  }, [location.pathname]);

  const renderMenuItems = (items) =>
    items.map(({ path, label, icon, role }) =>
      (!role || user?.role === role) && (
        <div key={path} className={cx('menu-item', 'menu-item-hoverable', { 'menu-item-active': active === path.split('/')[1] })}>
          <NavLink className='flex items-center h-full w-full' to={path}>
            <span className='text-2xl ltr:mr-2 rtl:ml-2'>{icon}</span>
            {!collapse && <span>{label}</span>}
          </NavLink>
        </div>
      )
    );

  return (
    <div className={cx('side-nav', { 'side-nav-expand': !collapse, 'side-nav-collapse': collapse })}>
      <div className='side-nav-header flex flex-col px-4 h-32'>
        <div className={cx('logo py-2', { 'px-6': collapse })}>
          <img src={darkMode ? Logo : Logo} alt='logo' className='h-16 w-auto m-auto' />
        </div>
        <p className='text-center font-bold text-lg'>INSIGHT PULSE</p>
      </div>
      <div className='side-nav-content flex flex-col'>
        <nav className='menu px-4 pb-4'>
          <div className='menu-group'>
            <ul>{renderMenuItems(menuItems)}</ul>
          </div>
        </nav>
        {user && (user.role === 'SuperAdmin' || user.role === 'Admin') && (
          <nav className='menu px-4 mt-auto'>
            <div className='menu-group mb-8 admin-group'>
              <ul>
                <div className='menu-item menu-item-transparent'><span>Administration</span></div>
                {renderMenuItems(adminMenuItems)}
              </ul>
            </div>
          </nav>
        )}
        <nav className='menu px-4 mt-auto'>
          <div className='menu-group'><ul>{renderMenuItems(accountMenuItems)}</ul></div>
        </nav>
      </div>
    </div>
  );
};

export default SideNav;
