import { httpsCallable } from 'firebase/functions';
import { functions } from '../ultils/firebase';
import { IResponse } from '@/model/Responses';

const useSocial = () => {
  const getYoutubeComments = async (
    id: string,
    maxComments: number,
  ): Promise<IResponse<{ title: string; comments: string[] }>> => {
    const method = httpsCallable<
      { id: string; maxComments: number },
      IResponse<{ title: string; comments: string[] }>
    >(functions, 'Social_getYoutubeComments');
    const res = await method({ id: id, maxComments: maxComments });
    return res.data;
  };

  const getTiktokComments = async (
    id: string,
    postUrl: string,
    maxComments: number,
  ): Promise<IResponse<{ title: string; comments: string[] }>> => {
    const method = httpsCallable<
      { id: string; postUrl: string; maxComments: number },
      IResponse<{ title: string; comments: string[] }>
    >(functions, 'Social_getTiktokComments');
    const res = await method({
      id: id,
      postUrl: postUrl,
      maxComments: maxComments,
    });
    return res.data;
  };

  const getFacebookComments = async (
    id: string,
    maxComments: number,
  ): Promise<IResponse<{ title: string; comments: string[] }>> => {
    const method = httpsCallable<
      { id: string; maxComments: number },
      IResponse<{ title: string; comments: string[] }>
    >(functions, 'Social_getFacebookComments');
    const res = await method({ id: id, maxComments: maxComments });
    return res.data;
  };

  return {
    getYoutubeComments,
    getTiktokComments,
    getFacebookComments,
  };
};

export default useSocial;
